@media all and (max-width : 991px) {
    .loginMain {
        .FormMain {
            .TextLogin {
                // text-align: center;
                margin: 0;
                padding: 0;
            }

            .FormWrapperLogin {
                margin-top: 0;
            }
        }
    }
}

@media all and (max-width : 1024px) {
    .loginMain {
        .FormMain {
            .TextLogin {
                // text-align: center;
                margin: 0;
                padding: 0;
            }

            .FormWrapperLogin {
                margin-top: -15px;
            }
        }
    }
}

@media all and (max-width : 640px) {
    .loginMain {
        .FormMain {
            .FormWrapperLogin {
                margin-top: -60px;
            }
        }
    }
}


@media all and (max-width : 300px) {
    .loginMain {
        .FormMain {
            .TextLogin {
                .Get_Started-Text {
                    font-size: 22px;
                }

                .Credential-Text {
                    font-size: 14px;
                }
            }
        }
    }
}

@media all and (max-width : 248px) {
    .loginMain {
        .FormMain {
            .TextLogin {
                .Get_Started-Text {
                    font-size: 21px;
                }

                .Credential-Text {
                    margin-top: 15px;
                    font-size: 13px;
                }
            }

            .FormWrapperLogin {
                margin-top: 10px;
            }
        }
        
        input::placeholder{
            font-size: 15px;
        }
    }

}

@media all and (max-width : 214px) {
    .loginMain {
        input::placeholder{
            font-size: 13px;
        }
    }
}
@media all and (max-width : 200px){
    .loginMain {
        .FormMain {
            .TextLogin {
                .Get_Started-Text {
                    font-size: 20px;
                }
            }
        }
        .Support_text {
            font-size: 13px;
            margin-right: -22px;
        }
        input::placeholder{
            font-size: 11px;
        }
    }
}

@media all and (max-width : 188px){
    .loginMain {
        input::placeholder{
            font-size: 11px;
            float: left;
            padding-top: 8px;
            position: relative;
            right: 6px;
        }
    }
}

@media all and (max-width : 160px){
    .loginMain {
        .Support_text {
            font-size: 12px;
            margin-right: -32px;
        }
        input::placeholder{
            font-size: 10px;
            float: left;
            padding-top: 8px;
            position: relative;
            right: 14px;
        }
    }

}